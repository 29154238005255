import React, { useState, useEffect } from "react";

const ScrollButton = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisible);
        return () => {
            window.removeEventListener("scroll", toggleVisible);
        };
    }, []);

    return (
        <div
            className="scroll-to-top scroll-to-target"
            style={{
                display: visible ? "inline" : "none",
            }}
            onClick={scrollToTop}
            aria-hidden="true"
        >
            <span
                className="fa fa-angle-up"
            ></span>
        </div>
    );
};

export default ScrollButton;
