import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
    return (
        <section className="error-page">
            <div className="container-fluid pb-50">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="error-page__inner">
                            <div className="error-page__title-box">
                                <img src="/images/resource/404.jpg" alt="404 Not Found" />
                                <h3 className="error-page__sub-title">Page not found!</h3>
                            </div>
                            <p className="error-page__text">
                                Sorry we can't find that page! The page you are looking <br />
                                for was never existed.
                            </p>
                            <Link to="/" className="theme-btn btn-style-one shop-now">
                                <span className="btn-title">Back to Home</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ErrorPage;
