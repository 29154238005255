import React, { useEffect, useState } from 'react';
import api from '../../api';
import { Link } from 'react-router-dom';

const Stories = () => {
    const [stories, setStories] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('/stories');
                setStories(response.data.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
    };

    return (
        <section className="blog-section p-2">
            <div className="container">
                <div className="text-center">
                    <h2 className="sec-title">Customer Stories</h2>
                </div>
                <div className="row">
                    {stories.map((post, index) => (
                        <div className="col-md-4" key={index}>
                            <div className="blog-block">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <a href={post.link}>
                                            <img src={post.banner} alt="Blog" className="img-fluid" />
                                        </a>
                                    </div>
                                    <div className="content-box">
                                        <ul className="post-meta">
                                            <li className="categories"><a href={post.link}>{post.title}</a></li>
                                            <li className="date">{formatDate(post.created_at)}</li>
                                        </ul>
                                        {/* <h5 className="product-name">{post.product}</h5>
                                        <div className="rating">
                                            {[...Array(post.rating)].map((_, i) => (
                                                <i className="fa fa-star" key={i}></i>
                                            ))}
                                            {[...Array(5 - post.rating)].map((_, i) => (
                                                <i className="fa fa-star-o" key={i}></i>
                                            ))}
                                        </div> */}
                                        <h6 className="title text-justify"><Link to='#' dangerouslySetInnerHTML={{ __html: post.description }}></Link></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Stories;
