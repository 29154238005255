import { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import FooterServices from '../components/FooterServices';
import BlogSection from "./Home/modules/BlogSection";

const WhyUs = (settings) => {
    const [metaData, setMetaData] = useState({});

    useEffect(() => {
        if (settings.setting && settings.setting.length > 0) {
            const meta = {};
            settings.setting.forEach((setting) => {
                if (setting.type === "facebook_link") {
                    meta.facebook_link = setting.value;
                }
                if (setting.type === "twitter_link") {
                    meta.twitter_link = setting.value;
                }
                if (setting.type === "instagram_link") {
                    meta.instagram_link = setting.value;
                }
                if (setting.type === "linkedin_link") {
                    meta.linkedin_link = setting.value;
                }
            });
            setMetaData(meta);
        }
    }, [settings]);

    return (
        <>
            <section className="about-page-title" style={{ backgroundImage: `url('/images/about-us.png')` }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="title">WHY US</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-us">
                <div className="container-fluid">
                    <div className="row align-items-center pt-5">
                        <div className="col-lg-12 pb-5">
                            <div className="text">
                                At <b>NIYA LIVING</b>, we offer more than just beautifully designed furniture—we bring over 30 years of experience, expertise, and a steadfast commitment to excellence in every step of our process. Our deep industry knowledge and hands-on approach ensure that each product we create is of the highest quality, crafted with precision and care.
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="heading">
                                In-House Wood Processing:
                            </div>
                            <div className="text">
                                We handle every step of our production process, beginning with the sourcing of wood in the form of logs and planks. This enables us to maintain full control over the cutting and shaping, ensuring that each piece meets precise specifications. By managing the entire process in-house, we can optimize both the strength and design integrity of the wood, offering highly customized and accurate results for every project.
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <img className="image" src='/images/why-us.png' alt='why us' />
                        </div>
                    </div>

                    <div className="row align-items-center pt-5">
                        <div className="col-lg-4">
                            <img className="image" src='/images/why-us.png' alt='why us' />
                        </div>
                        <div className="col-lg-8">
                            <div className="heading">
                                Chemical Treatment & Seasoning:
                            </div>
                            <div className="text">
                                To enhance durability and longevity; our wood undergoes a two-step treatment process. First, we apply chemical treatment to protect the wood from pests and decay. Then, the wood is seasoned, carefully dried to achieve the ideal moisture content. This treatment strengthens the wood, increases its stability, and ensures resistance to environmental changes.
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center pt-5">
                        <div className="col-lg-8">
                            <div className="heading">
                                Manufacturing with Imported Advanced Machinery Setup:
                            </div>
                            <div className="text">
                                All of our manufacturing is done in-house using an imported advanced machinery setup. This allows us to maintain complete control over every aspect of production. With state-of-the-art equipment, we ensure that each piece meets international standards, providing both outstanding structural integrity and precise aesthetic details. This advanced manufacturing setup guarantees that our furniture is not only visually striking but also durable and built to last.
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <img className="image" src='/images/why-us.png' alt='why us' />
                        </div>
                    </div>

                    <div className="row align-items-center pt-5">
                        <div className="col-lg-4">
                            <img className="image" src='/images/why-us.png' alt='why us' />
                        </div>
                        <div className="col-lg-8">
                            <div className="heading">
                                Skilled Craftsmanship:
                            </div>
                            <div className="text">
                                Our artisans bring years of expertise and attention to detail to every piece. From intricate joinery to seamless finishes, the level of craftsmanship in each item reflects our unwavering commitment to quality. Their skill and dedication ensure that every piece meets the highest standards of excellence.
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center pt-5">
                        <div className="col-lg-8">
                            <div className="heading">
                                Sanding & Polishing:
                            </div>
                            <div className="text">
                                Every piece is expertly sanded and polished to achieve a flawless finish. With a versatile range of finishes development by professionals, we enhance the natural beauty of the materials, ensuring a refined, luxurious texture that perfectly complements each design.
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <img className="image" src='/images/why-us.png' alt='why us' />
                        </div>
                    </div>

                    <div className="row align-items-center pt-5">
                        <div className="col-lg-4">
                            <img className="image" src='/images/why-us.png' alt='why us' />
                        </div>
                        <div className="col-lg-8">
                            <div className="heading">
                                Packaging:
                            </div>
                            <div className="text">
                                Our commitment to quality extends to packaging. Each product is carefully packaged with protective materials to prevent any damage during transit. We use durable and secure packing techniques to ensure that your furniture arrives in perfect condition, just as it left our workshop.
                            </div>
                        </div>

                    </div>

                    <div className="row align-items-center pt-5">
                        <div className="col-lg-8">
                            <div className="heading">
                                Quality Control:
                            </div>
                            <div className="text">
                                We implement rigorous internal quality control at every stage of the production process. From raw material selection to final product inspection, our experienced QC team meticulously ensures that each piece meets our high standards. This process guarantees that only the finest, defect-free furniture reaches our customers.
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <img className="image" src='/images/why-us.png' alt='why us' />
                        </div>
                    </div>

                </div>
            </section>

            <section className="follow-social-media">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <h2 className="heading">
                                Follow Us on Social Media
                            </h2>
                            <p className="text">
                                Stay inspired and updated with our latest designs and offers:
                            </p>
                            <ul className="social-icon-two justify-content-center pt-2">
                                <li>
                                    <Link to={metaData.twitter_link}>
                                        <i className="fab fa-twitter"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={metaData.instagram_link}>
                                        <i className="fab fa-instagram"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={metaData.facebook_link}>
                                        <i className="fab fa-facebook"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={metaData.linkedin_link}>
                                        <i className="fab fa-linkedin-in"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default WhyUs;
