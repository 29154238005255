import React, { useState } from 'react';

const ProductStatement = (addData) => {
    if (!addData.addData?.additonal_heading) {
        return null;
    }
    return (
        <section className="product-statement-section">
            <div className="container-fluid">

                <div className="row">

                    <div className="col-lg-6 col-sm-12">
                    {addData.addData.additonal_image && (
                           <img 
                           src={addData.addData.additonal_image} 
                           alt={addData.addData.additonal_heading} 
                           style={{ borderRadius: '10px' }} 
                         />                         
                        )}
                    </div>

                    <div className="col-lg-6 col-sm-12">
                        <h3 className="sec-title text-justify">{addData.addData.additonal_heading}</h3>
                        <p className="text text-justify" dangerouslySetInnerHTML={{ __html: addData.addData.additonal_description }}></p>

                    </div>
                </div>
            </div>
        </section>
    );
};
export default ProductStatement;
