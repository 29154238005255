import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import api from '../../../api';
import { toast } from 'react-toastify';

const Sidenav = () => {
    const location = useLocation();
    const [loginData, setLoginData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const sidenavRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const userData = sessionStorage.getItem('user_data');
        if (userData) {
            try {
                const parsedData = JSON.parse(userData);
                setLoginData(parsedData);
            } catch {
                setLoginData(null);
            }
        } else {
            setLoginData(null);
        }
    }, []);

    const isActive = (path) => {
        return location.pathname === path
            ? { itemClass: "mm-active", linkClass: "active" }
            : { itemClass: "", linkClass: "" };
    };

    const handleLogout = async (e) => {
        e.preventDefault();
        setLoading(true);

        const token = sessionStorage.getItem('access_token');
        try {
            const response = await api.get('/auth/logout', {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.data.result) {
                Cookies.remove('user_data');
                localStorage.removeItem('auth_token');
                setLoginData(null);
                navigate('/login', { state: { message: 'Logged out successfully' } });
            } else {
                toast.error(response.data.message || "An error occurred");
            }
        } catch (err) {
            toast.error(err.response?.data?.message || "An error occurred");
        } finally {
            setLoading(false);
        }
    };

    const toggleSidenav = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (sidenavRef.current && !sidenavRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <>
            <div className="col-xl-12 user-header">
                <div className="top-user-info">
                    <span className="avatar">
                        {loginData && loginData.avatar ? (
                            <img src={loginData.avatar} className="img-fit h-100" alt="Avatar" />
                        ) : (
                            <img src="/images/user.jpg" className="img-fit h-100" alt="Avatar" />
                        )}
                    </span>
                    <div className="user-contact ms-3">
                        <h4 className="name mt-2">{loginData ? loginData.name : 'Guest'}</h4>
                        <div className="email">{loginData ? loginData.email : 'No Email'}</div>
                    </div>
                </div>

                {/* Toggle Button */}
                <button
                    className="btn btn-sm aiz-mobile-toggle"
                    onClick={toggleSidenav}
                >
                    <i className={`icon ${isOpen ? 'icon lnr-icon-cog' : 'icon lnr-icon-cog'} la-2x`}></i>
                </button>
            </div>
            <div className="col-xl-12">
                {/* Sidenav */}
                <div
                    className={`aiz-user-sidenav ${isOpen ? 'open' : ''}`}
                    ref={sidenavRef}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '250px',
                        height: '100%',
                        background: '#fff',
                        zIndex: 1050,
                        transform: isOpen ? 'translateX(0)' : 'translateX(-100%)',
                        transition: 'transform 0.3s ease',
                        boxShadow: isOpen ? '2px 0 5px rgba(0, 0, 0, 0.2)' : 'none',
                    }}
                >
                    <button
                        className="btn btn-close"
                        onClick={toggleSidenav}
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            background: 'transparent',
                            border: 'none',
                            fontSize: '25px',
                            cursor: 'pointer',
                            zIndex: 1051,
                        }}
                    >
                        &times;
                    </button>
                    <div className="aiz-user-info">
                        <span className="avatar">
                            {loginData && loginData.avatar ? (
                                <img src={loginData.avatar} className="img-fit h-100" alt="Avatar" />
                            ) : (
                                <img src="/images/user.jpg" className="img-fit h-100" alt="Avatar" />
                            )}
                        </span>
                        <h4 className="name mt-2">{loginData ? loginData.name : 'Guest'}</h4>
                        <div className="email">{loginData ? loginData.email : 'No Email'}</div>
                    </div>

                    <div className="sidemenu">
                        <ul className="aiz-side-nav-list mb-3 pb-3 border-bottom metismenu">
                            <li className={`aiz-side-nav-item ${isActive("/dashboard").itemClass}`}>
                                <Link to="/dashboard" className={`aiz-side-nav-link ${isActive("/dashboard").linkClass}`}>
                                    <span className="icon lnr lnr-icon-home"></span>
                                    <span className="aiz-side-nav-text ms-3">Dashboard</span>
                                </Link>
                            </li>
                            <li className={`aiz-side-nav-item ${isActive("/purchase-history").itemClass}`}>
                                <Link
                                    to="/purchase-history"
                                    className={`aiz-side-nav-link ${isActive("/purchase-history").linkClass}`}
                                >
                                    <span className="icon lnr lnr-icon-history"></span>
                                    <span className="aiz-side-nav-text ms-3">Purchase History</span>
                                </Link>
                            </li>
                            <li className={`aiz-side-nav-item ${isActive("/wishlists").itemClass}`}>
                                <Link to="/wishlists" className={`aiz-side-nav-link ${isActive("/wishlists").linkClass}`}>
                                    <span className="icon lnr lnr-icon-heart"></span>
                                    <span className="aiz-side-nav-text ms-3">Wishlist</span>
                                </Link>
                            </li>
                            <li className={`aiz-side-nav-item ${isActive("/profile").itemClass}`}>
                                <Link to="/profile" className={`aiz-side-nav-link ${isActive("/profile").linkClass}`}>
                                    <span className="icon lnr lnr-icon-license"></span>
                                    <span className="aiz-side-nav-text ms-3">Manage Profile</span>
                                </Link>
                            </li>
                        </ul>

                        <Link to="/logout" className="btn btn-primary" onClick={handleLogout}>
                            <span className="icon lnr lnr-icon-power-switch"></span>
                            <span className="aiz-side-nav-text ms-3">Log Out</span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidenav;