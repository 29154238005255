import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Sidenav from "./modules/Sidenav";
import api from "../../api";

const OrderDetails = () => {
    const { id } = useParams();
    const [orderDetails, setOrderDetails] = useState({});
    const [orderItems, setOrderItems] = useState([]);
    const [loadingDetails, setLoadingDetails] = useState(true);
    const [loadingItems, setLoadingItems] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOrderDetails = async () => {
            const token = sessionStorage.getItem('access_token');
            try {
                const response = await api.get(`/purchase-history-details/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setOrderDetails(response.data.data[0] || {});
            } catch (err) {
                setError('Failed to load order details');
            } finally {
                setLoadingDetails(false);
            }
        };

        fetchOrderDetails();
    }, [id]);

    useEffect(() => {
        const fetchOrderItems = async () => {
            const token = sessionStorage.getItem('access_token');
            try {
                const response = await api.get(`/purchase-history-items/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setOrderItems(response.data.data || []);
            } catch (err) {
                setError('Failed to load order items');
            } finally {
                setLoadingItems(false);
            }
        };

        fetchOrderItems();
    }, [id]);

    if (loadingDetails || loadingItems) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <section className="dashboard">
            <div className="container-fluid">
                <Sidenav />
                <div className="aiz-user-panel">
                    <div className="aiz-titlebar mb-4">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h1 className="fs-20 fw-700 text-dark">Order Id: {orderDetails.code || 'N/A'}</h1>
                            </div>
                        </div>
                    </div>

                    <div className="card rounded-0 shadow-none border mb-4">
                        <div className="card-header border-bottom-0">
                            <h5 className="fs-16 fw-700 text-dark mb-0">Order Summary</h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <table className="table-borderless table">
                                        <tbody>
                                            <tr>
                                                <td className="w-50 fw-600">Order Code:</td>
                                                <td>{orderDetails.code || 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td className="w-50 fw-600">Customer:</td>
                                                <td>{orderDetails.shipping_address?.name || 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td className="w-50 fw-600">Email:</td>
                                                <td>{orderDetails.shipping_address?.email || 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td className="w-50 fw-600">Shipping address:</td>
                                                <td>
                                                    {orderDetails.shipping_address?.address || 'N/A'}, {orderDetails.shipping_address?.city || 'N/A'}, {orderDetails.shipping_address?.state || 'N/A'}, {orderDetails.shipping_address?.country || 'N/A'} - {orderDetails.shipping_address?.postal_code || 'N/A'}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-lg-6">
                                    <table className="table-borderless table">
                                        <tbody>
                                            <tr>
                                                <td className="w-50 fw-600">Order date:</td>
                                                <td>{orderDetails.date || 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td className="w-50 fw-600">Order status:</td>
                                                <td>{orderDetails.delivery_status_string || 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td className="w-50 fw-600">Total order amount:</td>
                                                <td>{orderDetails.grand_total || 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td className="w-50 fw-600">Shipping method:</td>
                                                <td>{orderDetails.shipping_type_string || 'Flat shipping rate'}</td>
                                            </tr>
                                            <tr>
                                                <td className="w-50 fw-600">Payment method:</td>
                                                <td>{orderDetails.payment_type || 'N/A'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row gutters-16">
                        <div className="col-md-12">
                            <div className="card rounded-0 shadow-none border mb-4">
                                <div className="card-header border-bottom-0">
                                    <h5 className="fs-16 fw-700 text-dark mb-0">Order Details</h5>
                                </div>
                                <div className="card-body table-responsive">
                                    <table className="aiz-table table footable footable-1 breakpoint-lg">
                                        <thead className="text-gray fs-12">
                                            <tr className="footable-header">
                                                <th className="pl-0 footable-first-visible">#</th>
                                                <th width="30%">Product</th>
                                                <th>Variation</th>
                                                <th>Quantity</th>
                                                <th>Status</th>
                                                <th>Price</th>
                                                <th data-breakpoints="md" className="text-right pr-0 footable-last-visible">Refund</th>
                                            </tr>
                                        </thead>
                                        <tbody className="fs-14">
                                            {orderItems.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="pl-0 footable-first-visible">{index + 1}</td>
                                                    <td>{item.product_name || 'N/A'}</td>
                                                    <td>{item.variation || 'N/A'}</td>
                                                    <td>{item.quantity || 0}</td>
                                                    <td>{item.delivery_status_string || 'N/A'}</td>
                                                    <td className="fw-700">{item.price || 'N/A'}</td>
                                                    <td className="text-xl-right pr-0 footable-last-visible">
                                                        {item.refund_button ? (
                                                            <button className="btn btn-danger btn-sm">Refund</button>
                                                        ) : (
                                                            <span>{item.refund_label || 'No Refund Available'}</span>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="card rounded-0 shadow-none border mt-2">
                                <div className="card-header border-bottom-0">
                                    <b className="fs-16 fw-700 text-dark">Order Amount</b>
                                </div>
                                <div className="card-body pb-0">
                                    <table className="table-borderless table">
                                        <tbody>
                                            <tr>
                                                <td className="w-50 fw-600">Subtotal</td>
                                                <td className="text-right">{orderDetails.subtotal || 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td className="w-50 fw-600">Shipping</td>
                                                <td className="text-right">{orderDetails.shipping_cost || 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td className="w-50 fw-600">Tax</td>
                                                <td className="text-right">{orderDetails.tax || 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td className="w-50 fw-600">Coupon</td>
                                                <td className="text-right">{orderDetails.coupon_discount || 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td className="w-50 fw-600">Total</td>
                                                <td className="text-right">
                                                    <strong>{orderDetails.grand_total || 'N/A'}</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OrderDetails;