import React, { useState, useEffect, useRef } from "react";
import MobileMenu from "./MobileMenu";
import CartBox from "./CartBox";
import UserProfile from "./UserProfile";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import api from "../api";
import Cookies from "js-cookie";

function Header({ cartItems, grandTotal, removeFromCart, wishlistCount }) {
  const [isSticky, setIsSticky] = useState(false);
  const location = useLocation();
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [metaData, setMetaData] = useState({});
  const [settings, setSettings] = useState([]);
  const [categories, setCategories] = useState([]);
  // const [wishlistCount, setWishlistCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/business-settings");
        setSettings(response.data.data.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchCategoriesAndSubcategories = async () => {
      try {
        const response = await api.get("/categories");
        const fetchedCategories = response.data.data;

        // Fetch subcategories for each category
        const categoriesWithSubcategories = await Promise.all(
          fetchedCategories.map(async (category) => {
            const subResponse = await api.get(`/sub-categories/${category.id}`);
            return {
              ...category,
              subcategories: subResponse.data.data,
            };
          })
        );

        setCategories(categoriesWithSubcategories);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoriesAndSubcategories();
  }, []);

  useEffect(() => {
    if (Array.isArray(settings)) {
      const meta = {};
      settings.forEach((setting) => {
        if (setting.type === "header_logo_path") {
          meta.header_logo_path = setting.value;
        }
        if (setting.type === "site_icon_path") {
          meta.site_icon_path = setting.value;
        }
        if (setting.type === "website_name") {
          meta.website_name = setting.value;
        }
        if (setting.type === "header_top") {
          meta.header_top = setting.value;
        }
        if (setting.type === "website_topbar_content") {
          meta.website_topbar_content = setting.value;
        }
        if (setting.type === "topbar_link_name") {
          meta.topbar_link_name = setting.value;
        }
        if (setting.type === "topbar_banner_link") {
          meta.topbar_banner_link = setting.value;
        }
      });
      setMetaData(meta);
    }
  }, [settings]);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuVisible((prev) => !prev);
    if (!isMobileMenuVisible) {
      document.body.classList.add("mobile-menu-visible");
    } else {
      document.body.classList.remove("mobile-menu-visible");
    }
  };

  const toggleSearchPopup = () => {
    setIsSearchActive((prev) => !prev);
    if (!isSearchActive) {
      document.body.classList.add("mobile-search-active");
    } else {
      document.body.classList.remove("mobile-search-active");
    }
  };

  const defaultMenuItems = [
    // { title: "Home", link: "/" },
    // { title: "Sale", link: "/products" },
  ];

  const categoryMenuItems = categories.map((category) => ({
    title: category.name,
    link: `/category/${category.slug}`,
    subMenu: category.subcategories?.map((sub) => ({
      title: sub.name,
      link: `/category/${sub.slug}`,
    })),
  }));

  const menuItems = [...defaultMenuItems, ...categoryMenuItems];

  const renderSubMenu = (subMenu) => {
    if (!subMenu || subMenu.length === 0) return null;

    return (
      <ul>
        {subMenu.map((item, index) => (
          <li key={index} className={item.subMenu ? "dropdown" : ""}>
            <Link to={item.link}>{item.title}</Link>
            {item.subMenu && renderSubMenu(item.subMenu)}
            {item.subMenu && <div className="dropdown-btn"><i className="fa-solid fa-angle-down"></i></div>}
          </li>
        ))}
      </ul>
    );
  };

  const renderMenuItems = () => {
    return menuItems.map((item, index) => (
      <li
        key={index}
        className={item.subMenu && item.subMenu.length > 0 ? "dropdown" : ""}
      >
        <Link to={item.link}>{item.title}</Link>
        {item.subMenu && item.subMenu.length > 0 && renderSubMenu(item.subMenu)}
        {item.subMenu && item.subMenu.length > 0 && (
          <div className="dropdown-btn"><i className="fa-solid fa-angle-down"></i></div>
        )}
      </li>
    ));
  };

  useEffect(() => {
    const searchProducts = async () => {
      if (searchTerm.length >= 3) {
        try {
          const response = await api.get(`/products/search?name=${searchTerm}`);
          setResults(response.data.data);
          setShowResults(true);
        } catch (error) {
          console.error("Error fetching search results", error);
        }
      } else {
        setShowResults(false);
      }
    };

    const debounceSearch = setTimeout(() => {
      searchProducts();
    }, 500);

    return () => clearTimeout(debounceSearch);
  }, [searchTerm]);

  const handleKeyUp = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowResults(false);
    }
  };

  const handleFocus = () => {
    if (results.length > 0) {
      setShowResults(true);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>{metaData.website_name}</title>
        <meta name="keywords" content={metaData.meta_keywords} />
        <meta name="title" content={metaData.meta_title} />
        <meta name="description" content={metaData.meta_description} />
        <meta name="csrf-token" content={metaData.csrf_token} />
      </Helmet>

      <header
        id="header"
        className={`main-header ${isSticky ? "fixed-header" : ""} ${isSearchActive ? "moblie-search-active" : ""
          }`}
      >
        {metaData.header_top && (
          <div className="header-top">
            <span
              className="header-top-text"
              dangerouslySetInnerHTML={{ __html: metaData.website_topbar_content }}
            ></span>
            &nbsp; | &nbsp;
            {metaData.topbar_banner_link && metaData.topbar_link_name && (
              <Link to={metaData.topbar_banner_link} className="header-top-link">
                {metaData.topbar_link_name}
              </Link>
            )}
          </div>
        )}

        <div className="header-lower">
          <div className="main-box">
            <div className="logo-box">
              <Link to="/">
                <div className="logo">
                  <img
                    src={metaData.header_logo_path}
                    alt={metaData.website_name}
                  />
                </div>
              </Link>
            </div>
            <div className="search-box" ref={searchRef} >
              <div className="search-inner">
                <form method="post" action="#">
                  <div className="form-group">
                    <input
                      type="search"
                      name="search-field"
                      placeholder="Search For a Product"
                      onKeyUp={handleKeyUp}
                      onFocus={handleFocus}
                      required
                    />
                    <button type="submit">
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                  {showResults && (
                    <ul className="search-results mt-2">
                      {results.length > 0 ? (
                        results.map((item) => (
                          <Link to={`/products/${item.slug}`} className="item-link">
                            <li key={item.id} className="search-item">

                              <img
                                src={item.thumbnail_image}
                                alt={item.name}
                                className="item-image"
                              />
                              <div className="item-details">
                                <span className="item-name">{item.name}</span>
                                <span className="item-price">{item.main_price}</span>
                              </div>

                            </li>
                          </Link>
                        ))
                      ) : (
                        <li className="no-results">No results found</li>
                      )}
                    </ul>
                  )}
                </form>
              </div>
            </div>
            <div className={`made-in-india ${location.pathname === "/" ? "show" : "hide"}`}>
              {location.pathname === "/" && (
                <>
                  <img
                    src="/images/make-in-india.png"
                    alt={metaData.website_name}
                  />
                  <span className="header-text">Made in India for India</span>
                </>
              )}
            </div>
          </div>
          <div className="main-box" style={{ justifyContent: 'unset' }}>
            <div className="outer-box2 mx-auto">
              <div className="nav-outer">
                <nav className="nav main-menu">
                  <ul className="navigation">{renderMenuItems()}</ul>
                </nav>
              </div>
            </div>
            <div className="outer-box">
              {/* <button
                className="ui-btn ui-btn search-btn"
                onClick={toggleSearchPopup}
              >
                <span className="icon lnr lnr-icon-search"></span>
              </button> */}
              <Link to="/wishlists" className="ui-btn ui-btn wishlist-btn">
                <span className="icon lnr lnr-icon-heart"></span>
                <span className="count">{wishlistCount}</span>
              </Link>
              <CartBox cartItems={cartItems} grandTotal={grandTotal} removeFromCart={removeFromCart} />
              <UserProfile />
             
              <Link to="/contact-us" className="ui-btn contact-btn">
                <span className="icon fa fa-user-headset"></span>
              </Link>
              
              <div className="mobile-nav-toggler" onClick={toggleMobileMenu}>
                <span className="icon lnr-icon-bars"></span>
              </div>
            </div>
          </div>
        </div>

        <div className="mobile-header">
          <div className="container-fluid">
            <div className="row align-items-end">
              <div className="col-4 left-box">
                <div className="mobile-nav-toggler" onClick={toggleMobileMenu}>
                  <span className="icon lnr-icon-bars"></span>
                </div>
                <UserProfile />
              </div>
              <div className="col-4 logo-box">
                <div className="logo">
                  <Link to="/">
                    <img
                      src={metaData.header_logo_path}
                      alt={metaData.website_name}
                    />
                  </Link>
                </div>
              </div>
              <div className="col-4 right-box">
                <Link to="/wishlists" className="ui-btn ui-btn wishlist-btn mx-3">
                  <span className="icon lnr lnr-icon-heart"></span>
                  <span className="count">{wishlistCount}</span>
                </Link>
                <CartBox cartItems={cartItems} grandTotal={grandTotal} removeFromCart={removeFromCart} />
              </div>
              <div className="col-12 search-box py-3" ref={searchRef} >
                <div className="search-inner">
                  <form method="post" action="#">
                    <div className="form-group">
                      <input
                        type="search"
                        name="search-field"
                        placeholder="Search For a Product"
                        onKeyUp={handleKeyUp}
                        onFocus={handleFocus}
                        required
                      />
                      <button type="submit">
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                    {showResults && (
                      <ul className="search-results mt-2">
                        {results.length > 0 ? (
                          results.map((item) => (
                            <Link to={`/products/${item.slug}`} className="item-link">
                              <li key={item.id} className="search-item">

                                <img
                                  src={item.thumbnail_image}
                                  alt={item.name}
                                  className="item-image"
                                />
                                <div className="item-details">
                                  <span className="item-name">{item.name}</span>
                                  <span className="item-price">{item.main_price}</span>
                                </div>

                              </li>
                            </Link>
                          ))
                        ) : (
                          <li className="no-results">No results found</li>
                        )}
                      </ul>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <MobileMenu
          isVisible={isMobileMenuVisible}
          toggleMenu={toggleMobileMenu}
          menuItems={menuItems}
        />

        {/* Search Popup */}
        {/* {isSearchActive && (
          <div className="search-popup">
            <span
              className="search-back-drop"
              onClick={toggleSearchPopup}
            ></span>
            <button className="close-search" onClick={toggleSearchPopup}>
              <span className="fa fa-times"></span>
            </button>
            <div className="search-inner">
              <form method="post" action="#">
                <div className="form-group">
                  <input
                    type="search"
                    name="search-field"
                    placeholder="Search..."
                    required
                  />
                  <button type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        )} */}

        <div className={`sticky-header ${isSticky ? "fixed-header" : ""}`}>
          <div className="container-fluid">
            <div className="inner-container">
              <div className="logo">
                <Link to="/">
                  <img
                    src={metaData.header_logo_path}
                    alt={metaData.website_name}
                  />
                </Link>
              </div>
              <div className="nav-outer">
                <nav className="main-menu">
                  <div className="navbar-collapse show collapse clearfix">
                    <ul className="navigation clearfix">{renderMenuItems()}</ul>
                  </div>
                </nav>
                <div className="mobile-nav-toggler" onClick={toggleMobileMenu}>
                  <span className="icon lnr-icon-bars"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
