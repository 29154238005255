import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import api from '../../../api';
import OwlCarousel from 'react-owl-carousel';

const BlogSection = () => {
    const [articles, setArticles] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('/blogs');
                setArticles(response.data.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const carouselOptions = {
        loop: true,
        margin: 10,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        }
    };

    if (loading) {
        return (
            <section className="news-section">
                <div className="container-fluid">
                    <div className="text-center">
                        <h3 className="sec-title">OUR LATEST ARTICLES</h3>
                    </div>
                    <div className="row">
                        {Array.from({ length: 6 }).map((_, index) => (
                            <div className="news-block col-lg-4 col-sm-6 wow fadeInUp" key={index}>
                                <div className="inner-box">
                                    <div className="image-box">
                                        <figure className="image">
                                            <Skeleton height={200} />
                                        </figure>
                                    </div>
                                    <div className="content-box">
                                        <h4 className="title fw-400 text-uppercase">
                                            <Skeleton height={30} width="80%" />
                                        </h4>
                                        <p className="fw-200 text-uppercase">
                                            <Skeleton height={20} count={2} />
                                        </p>
                                        <Link to="#" className="read-more">
                                            <Skeleton height={30} width={100} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <section className="news-section">
            <div className="container-fluid">
                <div className="text-center">
                    <h3 className="sec-title">OUR LATEST ARTICLES</h3>
                </div>

                {/* Mobile: Owl Carousel */}
                <div className="owl-carousel-wrapper d-block d-sm-none">
                    <OwlCarousel className="news-carousel  owl-theme default-dots" {...carouselOptions}>
                        {articles.slice(0, 6).map((article) => (
                            <div className="news-block" key={article.id}>
                                <div className="inner-box">
                                    <div className="image-box">
                                        <figure className="image">
                                            <Link to={`/blog/${article.slug}`}>
                                                <img src={article.banner} alt={`Blog Post ${article.id}`} />
                                            </Link>
                                        </figure>
                                    </div>
                                    <div className="content-box">
                                        <h4 className="title">
                                            <Link to={`/blog/${article.slug}`}>{article.title}</Link>
                                        </h4>
                                        <p className="text">{article.description.replace(/<\/?[^>]+(>|$)/g, "")}</p>
                                        <Link to={`/blog/${article.slug}`} className="read-more">
                                            READ MORE
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                </div>

                {/* Desktop: Grid layout */}
                <div className="row d-none d-sm-flex">
                    {articles.slice(0, 3).map((article) => (
                        <div className="news-block col-lg-4 col-sm-6 wow fadeInUp" key={article.id}>
                            <div className="inner-box">
                                <div className="image-box">
                                    <figure className="image">
                                        <Link to={`/blog/${article.slug}`}>
                                            <img src={article.banner} alt={`Blog Post ${article.id}`} />
                                        </Link>
                                    </figure>
                                </div>
                                <div className="content-box">
                                    <h4 className="title">
                                        <Link to={`/blog/${article.slug}`}>{article.title}</Link>
                                    </h4>
                                    <p className="text">{article.description.replace(/<\/?[^>]+(>|$)/g, "")}</p>
                                    <Link to={`/blog/${article.slug}`} className="read-more">
                                        READ MORE
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default BlogSection;
