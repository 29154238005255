import { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import FooterServices from '../components/FooterServices';
import BlogSection from "./Home/modules/BlogSection";

const ContactUs = (settings) => {
    const [metaData, setMetaData] = useState({});

    useEffect(() => {
        if (settings.setting && settings.setting.length > 0) {
            const meta = {};
            settings.setting.forEach((setting) => {
                if (setting.type === "facebook_link") {
                    meta.facebook_link = setting.value;
                }
                if (setting.type === "twitter_link") {
                    meta.twitter_link = setting.value;
                }
                if (setting.type === "instagram_link") {
                    meta.instagram_link = setting.value;
                }
                if (setting.type === "linkedin_link") {
                    meta.linkedin_link = setting.value;
                }
            });
            setMetaData(meta);
        }
    }, [settings]);
    return (
        <>
            <section className="contact-page-title" style={{ backgroundImage: `url('/images/contact-us.png')` }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="title">GET IN TOUCH WITH US</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-us">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="text">
                                At <b>NIYA LIVING</b>, we’re here to assist you every step of the way. Whether you have a question about our products, need help with an order, or want to explore customization options, we’re just a message away.                            </div>
                        </div>
                        <div className="col-lg-10 d-flex justify-content-center mt-3">
                            <div className="contact-info">
                                <span>Email: </span>
                                <Link to="mailto:care@niyaliving.in">care@niyaliving.in</Link>
                            </div>
                        </div>

                        <div className="col-lg-10 contact-us-form">
                            <h2 className='form-title'>Send Us a Message</h2>
                            <span className="form-sub-title py-2">Use the form below to send us your query, and our team will respond promptly.</span>
                            <form className="form pt-4" action="" method="post">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <input name="name" className="form-control" type="text" placeholder="Enter Name *" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <input name="surname" className="form-control" type="text" placeholder="Enter Surname" />
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <input name="phone" className="form-control" type="text" placeholder="Enter Phone" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <input name="email" className="form-control" type="email" placeholder="Enter Email *" />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <textarea name="message" className="form-control" rows="7" placeholder="Enter Message"></textarea>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="theme-btn btn-style-one" data-loading-text="Please wait..."><span className="btn-title">Send message</span></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section className="follow-social-media">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <h2 className="heading">
                                Follow Us on Social Media
                            </h2>
                            <p className="text">
                                Stay inspired and updated with our latest designs and offers:
                            </p>
                            <ul className="social-icon-two justify-content-center pt-2">
                                <li>
                                    <Link to={metaData.twitter_link}>
                                        <i className="fab fa-twitter"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={metaData.instagram_link}>
                                        <i className="fab fa-instagram"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={metaData.facebook_link}>
                                        <i className="fab fa-facebook"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={metaData.linkedin_link}>
                                        <i className="fab fa-linkedin-in"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactUs;
