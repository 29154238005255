import React, { useEffect, useState } from 'react';

const SitemapPage = () => {
    const [links, setLinks] = useState([]);

    useEffect(() => {
        const fetchSitemap = async () => {
            try {
                const response = await fetch('/sitemap.xpl');
                const text = await response.text();

                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(text, 'application/xml');

                const pathElements = xmlDoc.getElementsByTagName('path');
                const urls = Array.from(pathElements).map((el) => `https://niyaliving.in/${el.textContent}`);

                setLinks(urls);
            } catch (error) {
                console.error('Error fetching or parsing sitemap:', error);
            }
        };

        fetchSitemap();
    }, []);

    return (
        <>
            <section className="about-page-title" style={{ backgroundImage: `url('/images/about-us.png')` }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="title">SITEMAP</h1>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <ul>
                    {links.map((link, index) => (
                        <li key={index}>
                            <a href={link} target="_blank" rel="noopener noreferrer">
                                {link}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default SitemapPage;
