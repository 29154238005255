import React, { useState, useEffect, useRef } from 'react';
import Sidenav from "./modules/Sidenav";
import api from "../../api";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

const PurchaseHistory = () => {
  const [loginData, setLoginData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [orderItems, setorderItems] = useState([]);



  useEffect(() => {
    const userData = sessionStorage.getItem('user_data');
    if (userData) {
      try {
        const parsedData = JSON.parse(userData);
        setLoginData(parsedData);
      } catch {
        setLoginData(null);
      }
    } else {
      setLoginData(null);
    }
  }, []);


  const fetchOrder = async () => {
    if (loginData && loginData.id) {
      const token = sessionStorage.getItem('access_token');
      const formData = new FormData();
      formData.append('user_id', loginData.id);

      const queryString = new URLSearchParams(formData).toString();

      try {
        const response = await api.get(`/purchase-history`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setorderItems(response.data.data);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    fetchOrder();
  }, [loginData]);

  const downloadInvoice = async (id) => {
    const token = sessionStorage.getItem("access_token");

    try {
      const response = await api.get(`/invoice/download/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      });

      const result = response.data;
      console.log(result.data);
      // return false;
      if (result) {
        const blob = new Blob([result], { type: 'application/pdf' });
        const objectUrl = URL.createObjectURL(blob);
        window.open(objectUrl, '_blank');
      } else {
        // toast.error(result.message || "Failed to fetch invoice.");
      }
    } catch (err) {
      console.error("Error fetching invoice:", err);
      // toast.error(err.response?.data?.message || "An error occurred while fetching the invoice.");
    }
  };

  const cancelOrder = async (id) => {
    const token = sessionStorage.getItem("access_token");

    try {
      const response = await api.get(`/order/cancel/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      const result = response.data;
      console.log(result);
      // return false;
      if (result) {
        fetchOrder();
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (err) {
      console.error("Error fetching invoice:", err);
      toast.error(err.response?.data?.message || "An error occurred while fetching the invoice.");
    }
  };

  return (
    <section className="dashboard">
      <div className="container-fluid">
        <Sidenav />

        <div className="aiz-user-panel">
          <div className="card shadow-none rounded-0 border">
            <div className="card-header border-bottom-0 bg-transparent">
              <h5 className="mb-0 fs-20 fw-700 text-dark">Purchase History</h5>
            </div>
            <div className="card-body">
              <table className="table aiz-table mb-0 footable footable-1 breakpoint-lg">
                <thead className="text-gray fs-12">
                  <tr className="footable-header">
                    <th className="pl-0">Code</th>
                    <th data-breakpoints="md">Date</th>
                    <th>Amount</th>
                    <th data-breakpoints="md">Delivery Status</th>
                    <th data-breakpoints="md">Payment Status</th>
                    <th data-breakpoints="md">Payment Type</th>
                    <th className="text-right pr-0">Options</th>
                  </tr>
                </thead>
                <tbody className="fs-14">
                  {orderItems.map(item => (
                    <tr className="footable-empty" key={item.code}>
                      <td>{item.code}</td>
                      <td>{item.date}</td>
                      <td>{item.grand_total}</td>
                      <td>{item.delivery_status}</td>
                      <td>
                        <span className={`badge ${item.payment_status === 'Un-Paid' ? 'badge-danger' : 'badge-success'}`}>
                          {item.payment_status}
                        </span>
                      </td>
                      <td>{item.payment_type}</td>
                      <td>
                        {item.delivery_status === 'Pending' && item.payment_status === 'Un-Paid' && (
                          <Link to="#" onClick={() => cancelOrder(item.id)} title="Order Details" className="badge badge-danger cancelOrder">
                            Cancel
                          </Link>
                        )}

                        <Link to={`/order-details/${item.id}`} title="Order Details">
                          <span className="icon lnr-icon-bars"></span>
                        </Link>

                        {item.payment_status === 'Paid' && (
                          <Link
                            className="ms-3"
                            to="#"
                            onClick={() => downloadInvoice(item.id)}
                            title="Download Invoice"
                          >
                            <span className="icon lnr-icon-download"></span>
                          </Link>
                        )}
                      </td>
                    </tr>
                  ))}

                </tbody>
              </table>
              <div className="aiz-pagination mt-2"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PurchaseHistory;