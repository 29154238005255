import React, { useEffect, useState } from 'react';
import Preloader from './components/Preloader';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import ScrollButton from './components/ScrollButton';
import Cart from './components/Cart';
import Checkout from './components/Checkout';
import Login from './components/Login';
import Registration from './components/Registration';
import NotFound from './components/NotFound';
import NewsletterPopup from "./components/NewsletterPopup";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from "./pages/Home/Home";
import Products from "./pages/Products/Products";
import ProductDetails from "./pages/Products/ProductDetails";
import ProductCategories from "./pages/Products/ProductCategories";
import ContactUs from "./pages/ContactUs";
import WhyUs from "./pages/WhyUs";
import Career from "./pages/Career";
import Sitemap from "./pages/Sitemap";
import Faqs from "./pages/Faqs";
import AboutUs from "./pages/AboutUs";
import Blogs from "./pages/Blogs/Blogs";
import BlogDetails from "./pages/Blogs/BlogDetails";
import Projects from "./pages/Projects/Projects";
import Dashboard from "./pages/Dashboard/Dashboard";
import PurchaseHistory from "./pages/Dashboard/PurchaseHistory";
import Wishlists from "./pages/Dashboard/Wishlists";
import Profile from "./pages/Dashboard/Profile";
import OrderDetails from "./pages/Dashboard/OrderDetails";
import ProjectDetails from "./pages/Projects/ProjectDetails";
import WarrantyPolicy from "./pages/Policy/WarrantyPolicy";
import ShippingPolicy from "./pages/Policy/ShippingPolicy";
import ReturnPolicy from "./pages/Policy/ReturnPolicy";
import PrivacyPolicy from "./pages/Policy/PrivacyPolicy";
import TermsConditions from "./pages/Policy/TermsConditions";
import Stories from "./pages/Stories/Stories";
import api from './api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import PaymentResponse from './components/PaymentResponse ';

function App() {
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loginData, setLoginData] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [grandTotal, setGrandTotal] = useState(null);
  const [wishlistCount, setWishlistCount] = useState(0);
  const [wishlistItem, setWishlistItem] = useState([]);

  const fetchCartData = async () => {
    let userId = null;
    const token = sessionStorage.getItem('access_token');
    
    if (loginData && loginData.id) {
      userId = loginData.id;
    } else {
      userId = getGuestId();
    }
  
    if (userId) {
      const formData = new FormData();
      formData.append(loginData && loginData.id ? 'user_id' : 'temp_user_id', userId);
  
      const queryString = new URLSearchParams(formData).toString();
  
      try {
        const response = await api.post(`/carts?${queryString}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setGrandTotal(response.data.grand_total || 0);
        setCartItems(response.data.data[0]?.cart_items || []);
        setLoading(false);
        setError(null);
      } catch (err) {
        setError('Failed to fetch cart data.');
        setLoading(false);
      }
    }
  };
  
  
  const getGuestId = () => {
    let guestId = getCookie('guest_id');
    if (!guestId) {
      guestId = generateGuestId();
      setCookie('guest_id', guestId, 30);
    }
    return guestId;
  };
  
  const generateGuestId = () => {
    return 'guest_' + Math.random().toString(36).substr(2, 9);
  };
  
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };
  
  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
  };
  

  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };
  
    document.addEventListener('contextmenu', handleContextMenu);
  
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);  

  useEffect(() => {
    fetchCartData();
  }, [loginData]);

  const handleWishlistRemove = async (slug) => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      toast.info("You need to be logged in to remove items from the wishlist.");
      return;
    }

    try {
      const response = await api.get(`/wishlists-remove-product/${slug}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (response.data && response.data.is_in_wishlist === false) {
        toast.success(response.data.message);

        // Update the local state to remove the item from the wishlist
        setWishlistItem((prevWishlists) =>
          prevWishlists.filter((item) => item.product.slug !== slug)
        );
        setWishlistCount(wishlistCount - 1); // Decrease wishlist count
      } else {
        toast.error("An error occurred while processing your request.");
      }
    } catch (err) {
      toast.error(err.response?.data?.message || "An error occurred. Please try again.");
    }
  };

  const removeFromCart = async (id) => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await api.delete(`/carts/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.result) {
        await fetchCartData();
        toast.success('Item removed from cart.');
      } else {
        setError('Failed to remove item from cart.');
      }
    } catch (err) {
      setError('Failed to remove item from cart.');
    }
  };

  const handleCart = async (id, variant, user_id, quantity) => {
    let guestId = null;
    let token = sessionStorage.getItem('access_token');
  
    if (!token) {
      guestId = getCookie('guest_id');  
  
      if (!guestId) {
        toast.info("You need to be logged in or have a guest session to add items to the cart.");
        return;
      }
  
      token = guestId;
    }
  
    try {
      const response = await api.post(
        '/carts/add',
        { 
          id, 
          variant, 
          [token === guestId ? 'temp_user_id' : 'user_id']: token === guestId ? guestId : user_id, 
          quantity 
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      if (response.data?.result) {
        toast.success(response.data.message);
        fetchCartData(token === guestId ? guestId : user_id);
      } else {
        toast.error(response.data.message || "An error occurred while processing your request.");
      }
    } catch (err) {
      toast.error(err.response?.data?.message || "An error occurred. Please try again.");
    }
  };
  

  const handleWishlist = async (slug) => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      toast.info("You need to be logged in to add items to the wishlist.");
      return;
    }

    try {
      const response = await api.get(`/wishlists-add-product/${slug}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data?.result) {
        fetchWishlistCount();
        toast.success(response.data.message);
      } else {
        toast.error("An error occurred while processing your request.");
      }
    } catch (err) {
      toast.error(err.response?.data?.message || "An error occurred. Please try again.");
    }
  };

  const fetchWishlistCount = async () => {
    const token = sessionStorage.getItem("access_token");
    if (!token) {
      setWishlistCount(0);
      return;
    }

    try {
      const response = await api.get("/wishlists", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setWishlistItem(response.data.data);
      setWishlistCount(response.data.data.length);
    } catch {
      setWishlistCount(0);
    }
  };

  useEffect(() => {
    fetchWishlistCount();
  }, []);

  useEffect(() => {
    const userData = sessionStorage.getItem('user_data');
    if (userData) {
      try {
        const parsedData = JSON.parse(userData);
        setLoginData(parsedData);
      } catch {
        setLoginData(null);
      }
    } else {
      setLoginData(null);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/business-settings');
        setSettings(response.data.data.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Router>
      <ToastContainer position="bottom-left" />
      <ScrollToTop />
      <NewsletterPopup />
      <div className="App">
        {loading && <Preloader />}
        <Header cartItems={cartItems} grandTotal={grandTotal} removeFromCart={removeFromCart} wishlistCount={wishlistCount} />
        <LocationToast />
        <Routes>
          <Route path="/" element={<Home handleCart={handleCart} handleWishlist={handleWishlist} />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/:slug" element={<ProductDetails handleCart={handleCart} handleWishlist={handleWishlist} />} />
          <Route path="/category/:slug" element={<ProductCategories handleCart={handleCart} handleWishlist={handleWishlist} />} />
          <Route path="/about-us" element={<AboutUs setting={settings} />} />
          <Route path="/contact-us" element={<ContactUs setting={settings} />} />
          <Route path="/why-us" element={<WhyUs setting={settings} />} />
          <Route path="/career" element={<Career setting={settings} />} />
          <Route path="/sitemap" element={<Sitemap setting={settings} />} />
          <Route path="/faqs" element={<Faqs setting={settings} />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog/:slug" element={<BlogDetails />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/project/:slug" element={<ProjectDetails />} />
          <Route path="/stories" element={<Stories />} />
          <Route path="/cart" element={<Cart cartItems={cartItems} />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/payment-response" element={<PaymentResponse />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={loginData ? <Dashboard /> : <Navigate to="/login" replace />} />
          <Route path="/purchase-history" element={<PurchaseHistory />} />
          <Route path="/order-details/:id" element={<OrderDetails />} />
          <Route path="/wishlists" element={<Wishlists handleCart={handleCart} wishlistItem={wishlistItem} handleWishlistRemove={handleWishlistRemove} />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/warranty-policy" element={<WarrantyPolicy />} />
          <Route path="/shipping-policy" element={<ShippingPolicy />} />
          <Route path="/return-policy" element={<ReturnPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer setting={settings} />
        <ScrollButton />
      </div>
    </Router>
  );
}
function LocationToast() {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.message) {
      toast.success(location.state.message);
    }
    if (location.state?.error) {
      toast.error(location.state.error);
    }
  }, [location.state]);

  return null;
}
export default App;
