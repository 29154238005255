import React, { useEffect, useState } from 'react';
import api from '../../../api';
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";

const StoriesSection = () => {
    const [stories, setStories] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const carouselOptions = {
        loop: true,
        margin: 10,
        nav: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsive: {
            0: { items: 2 },
            600: { items: 2 },
            1000: { items: 4 },
        },
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('/stories');
                setStories(response.data.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <section className="project-section-five">
            <div className="container-fluid">
                <div className="text-center">
                    <h3 className="sec-title">STORIES THAT CONNECT US</h3>
                </div>

                <div className="owl-carousel-wrapper">
                    <OwlCarousel className="projects-carousel-five owl-theme" {...carouselOptions}>
                        {stories.map((story) => (
                            <div className="project-block-five" key={story.id}>
                                <div className="inner-box">
                                    <div className="image-box">
                                        <figure className="image">
                                            <Link to="/stories" className="lightbox-image" aria-label={`View details about ${story.name}`}>
                                                <img
                                                    src={story.banner}
                                                    alt={story.title}
                                                    loading="lazy"
                                                    onError={(e) => (e.target.src = "/images/fallback.jpg")}
                                                />
                                            </Link>
                                        </figure>
                                    </div>
                                    <div className="content-box">
                                        <Link to="/stories">
                                            <h6 className="title">{story.title}</h6>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                </div>

                <div className="text-center pt-4">
                    <Link to={`/stories`} className="read-more">
                        View All
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default StoriesSection;
