import React from 'react';

const WarrantyPolicy = () => {
  return (
    <div className="auto-container py-5 text-justify">
      <h2>Warranty Policy</h2>
      <p>At Niya Living, we are committed to delivering high-quality furniture that is designed to last. Review our warranty policy below.</p>

      <h4>Warranty Duration</h4>
      <p>Our warranty lasts for 12 months from the date of purchase.</p>

      <h4>Warranty Coverage</h4>
      <ul>
        <li>The warranty covers defects in materials and workmanship.</li>
        <li>It does not cover damages resulting from misuse, neglect, or normal wear and tear.</li>
      </ul>

      <h4>Claim Process</h4>
      <ol>
        <li><strong>Contact Us:</strong> Reach out to us at <a className="text-dark" href='mailto:contact@niyaliving.in'>contact@niyaliving.in</a> within 12 months of purchase.</li>
        <li><strong>Provide Details:</strong> Include details of the defect and your order number.</li>
        <li><strong>Evaluation:</strong> Our team will evaluate the claim and determine the appropriate action, which may include repair or replacement.</li>
      </ol>

      <h4>Exclusions</h4>
      <p>This warranty does not cover:</p>
      <ul>
        <li>Damage due to accidents, abuse, or improper care.</li>
        <li>Minor imperfections or variations in wood and finish, as these are natural characteristics of solid wood.</li>
      </ul>

      <h4>Limitations</h4>
      <p>
        Our liability is limited to the repair or replacement of the defective product only. We are not responsible for any incidental or consequential damages arising from the use of the product.
      </p>

      <h4>Contact Us</h4>
      <p>For any questions regarding our warranty policy, please reach out to us at <a className="text-dark" href='mailto:contact@niyaliving.in'>contact@niyaliving.in</a>.</p>
    </div>
  );
};

export default WarrantyPolicy;
