import axios from 'axios';
import Cookies from 'js-cookie';

const ApiUrl = "https://admin.niyaliving.in/api/v2";
// const ApiUrl = "https://furniture.deveuonusit.xyz/api/v2";

const api = axios.create({
  baseURL: ApiUrl,
  headers: {
    'Content-Type': 'application/json',
    'System-Key': '123456',
    'X-Requested-With' : 'XMLHttpRequest'
  },
});

// api.interceptors.request.use(config => {
//   const csrfToken = Cookies.get('X-CSRF-TOKEN');
//   if (csrfToken) {
//     config.headers['X-CSRF-TOKEN'] = csrfToken;
//   }
//   return config;
// }, error => {
//   return Promise.reject(error);
// });

export default api;
