import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import api from "../../../api";
import Skeleton from "react-loading-skeleton"; 

const ProjectSection = () => {
  const options = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: false,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    items: 3,
    dots: true,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 },
    },
  };

  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/projects");
        //console.log(response.data);
        setProjects(response.data.data);
      } catch (err) {
        console.error("API Error:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <section className="project-section">
      <div className="container-fluid">
        <div className="text-center">
          <h3 className="sec-title">OUR PROJECT HIGHLIGHTS</h3>
        </div>
      </div>

      <div className="carousel-outer">
        {loading ? (
          <div>
            <OwlCarousel
              className="projects-carousel owl-theme default-dots"
              {...options}
            >
              {Array(3)
                .fill()
                .map((_, index) => (
                  <div className="project-block" key={index}>
                    <div className="inner-box">
                      <div className="image-box">
                        <Skeleton height={200} />
                      </div>
                      <div className="content-box">
                        <Skeleton width={150} />
                        <Skeleton count={2} />
                      </div>
                    </div>
                  </div>
                ))}
            </OwlCarousel>
          </div>
        ) : error ? (
          <div>Error fetching data</div>
        ) : (
          <OwlCarousel
            className="projects-carousel owl-theme default-dots"
            {...options}
          >
            {projects.map((project, index) => (
              <div className="project-block" key={index}>
                <div className="inner-box">
                  <div className="image-box">
                    <figure className="image">
                      <Link to={`/project/${project.slug}`}>
                        <img src={project.banner} alt={project.title} />
                      </Link>
                    </figure>
                  </div>
                  <div className="content-box">
                    <Link
                      to={`/project/${project.slug}`}
                      className="theme-btn read-more"
                    >
                      <i className="fa far fa-arrow-up"></i>
                    </Link>
                    <h4 className="title">
                      <Link to={`/project/${project.slug}`}>
                        {project.title}
                      </Link>
                    </h4>
                    <span className="cat">{project.client}</span>
                  </div>
                  <div className="overlay-1"></div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        )}
      </div>
    </section>
  );
};

export default ProjectSection;
