import React from "react";

const PageTitle = ({ category }) => {
    if (!category) return null;

    return (
        <section
            className="products-page-title"
            style={{ backgroundImage: `url(${category.banner})` }}
        >
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="title-outer">
                            <h1 className="title">{category.name}</h1>
                            <span className="text">{category.description}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PageTitle;
