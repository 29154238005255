import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidenav from "./modules/Sidenav";
import AddressModal from "./modules/AddressModal";
import api from "../../api";

const UserDashboard = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loginData, setLoginData] = useState(null);
    const [AddressData, setAddressData] = useState(null);
    const [wishlistCount, setWishlistCount] = useState(0);
    const [cartsCount, setCartsCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchWishlistCount = async () => {
            const token = sessionStorage.getItem('access_token');

            try {
                const response = await api.get('/wishlists', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setWishlistCount(response.data.data.length);
            } catch (err) {
                console.error("Error fetching wishlist count:", err);
            }
        };

        fetchWishlistCount();
    }, []);



    useEffect(() => {
        const fetchData = async () => {
            if (loginData && loginData.id) {
                const token = sessionStorage.getItem('access_token');

                try {
                    const response = await api.post(
                        `/cart-count`,
                        { user_id: loginData.id },
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`,
                            },
                        }
                    );

                    setCartsCount(response.data.count);
                } catch (err) {
                    setError(err.response?.data?.message || "An error occurred while fetching cart count.");
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [loginData]);


    useEffect(() => {
        const userData = sessionStorage.getItem('user_data');
        if (userData) {
            try {
                const parsedData = JSON.parse(userData);
                setLoginData(parsedData);
            } catch {
                setLoginData(null);
            }
        } else {
            setLoginData(null);
        }
    }, []);


    useEffect(() => {
        const fetchAddressData = async () => {
            setLoading(true);
            setError(null);

            try {
                const token = sessionStorage.getItem('access_token');
                const response = await api.get('/user/shipping/address', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });

                if (response.data.success) {
                    setAddressData(response.data.data[0]);
                } else {
                    // setError("Failed to fetch address");
                }
            } catch (err) {
                // setError(err.response?.data?.message || "An error occurred");
            } finally {
                setLoading(false);
            }
        };

        if (loginData) {
            fetchAddressData();
        }
    }, [loginData]);

    const openModal = () => {
        setIsModalOpen(true);
    };


    const closeModalAndDropdown = () => {
        setIsModalOpen(false);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <section className="dashboard">
            <div className="container-fluid">
                    <Sidenav login={loginData} />

                    <div className="aiz-user-panel">
                        <div className="row">
                            {/* Count Summary */}
                            <div className="col-xl-4 col-md-6 mb-4">
                                <div className="expenditure p-4 h-100">
                                    <div className="d-flex align-items-center pb-4">
                                        <span className="aiz-icon fa-solid fa-indian-rupee-sign"></span>
                                        <div className="ms-3 d-flex flex-column justify-content-between">
                                            <span className="fs-18 fw-400 text-white mb-1">Total Expenditure</span>
                                            <span className="fs-22 fw-700 text-white">₹0.00</span>
                                        </div>
                                    </div>
                                    <Link to="/purchase-history" className="fs-16 text-white">
                                        View Order History
                                        <span className="icon lnr lnr-icon-chevron-right ms-2"></span>
                                    </Link>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6 mb-4">
                                <div className="dashboard-product px-4 bg-white border h-100">
                                    <div className="d-flex align-items-center py-4 border-bottom">
                                        <span className="icon lnr lnr-icon-cart"></span>
                                        <div className="ms-3 d-flex flex-column justify-content-between">
                                            <span className="fs-22 fw-700 mb-1">{cartsCount}</span>
                                            <span className="fs-16 fw-400 text-secondary">Products in Cart</span>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center py-4 border-bottom">
                                        <span className="icon lnr lnr-icon-heart"></span>
                                        <div className="ms-3 d-flex flex-column justify-content-between">
                                            <span className="fs-22 fw-700 mb-1">{wishlistCount}</span>
                                            <span className="fs-16 fw-400 text-secondary">Products in Wishlist</span>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center py-4">
                                        <span className="icon lnr lnr-icon-cart1"></span>
                                        <div className="ms-3 d-flex flex-column justify-content-between">
                                            <span className="fs-22 fw-700 mb-1">0</span>
                                            <span className="fs-16 fw-400 text-secondary">Total Products Ordered</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6 mb-4">
                                <div className="p-4 border h-100  bg-white">
                                    <h6 className="fw-700 mb-3 text-dark">Default Shipping Address</h6>
                                    <ul className="list-unstyled mb-5">

                                        {AddressData ? (
                                            <>
                                                <li className="fs-14 fw-400 text-derk pb-1">{AddressData.address}</li>
                                                <li className="fs-14 fw-400 text-derk pb-1">{AddressData.postal_code} - {AddressData.city_name}</li>
                                                <li className="fs-14 fw-400 text-derk pb-1">{AddressData.state_name},</li>
                                                <li className="fs-14 fw-400 text-derk pb-1">{AddressData.country_name}.</li>
                                                <li className="fs-14 fw-400 text-derk pb-1">{AddressData.phone}</li>
                                            </>
                                        ) : (
                                            <li className="fs-14 fw-400 text-derk pb-1">No default address available</li>
                                        )}
                                    </ul>
                                    <button className="btn btn-dark btn-block fs-14 fw-500" onClick={openModal}
                                        style={{ borderRadius: '25px' }}>
                                        <i className="icon lnr lnr-icon-plus fs-18 fw-700 me-2"></i>
                                        Add New Address
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            {/* New Address Modal */}
            {loginData && (
                <AddressModal user_id={loginData.id} isOpen={isModalOpen} onClose={closeModalAndDropdown} />
            )}
        </section>
    );
};

export default UserDashboard;
