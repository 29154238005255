import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import api from "../api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email_or_phone: "",
    password: "",
    password_confirmation: "",
    register_by: 'email',
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const response = await api.post('/auth/signup', formData);
      if (response.data.result) {
        localStorage.setItem('access_token', response.data.access_token);
        toast.success('Registration successful! Please log in.');
        navigate('/login');
      } else {
        toast.error(response.data.message || "An error occurred");
      }
    } catch (err) {
      toast.error(err.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="login-form">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-10 mx-auto py-lg-4">
            <div className="card shadow-none rounded-0 border-0">
              <div className="row">
                <div className="col-lg-6">
                  <img
                    src="images/collection.png"
                    alt="Customer Register Page"
                    className="img-fit h-100"
                  />
                </div>

                <div className="col-lg-6 p-4 p-lg-5 d-flex flex-column justify-content-center border">
                  <div className="logo mb-3 mx-auto mx-lg-0">
                    <img
                      src="images/favicon.png"
                      alt="Site Icon"
                      className="img-fit h-100"
                    />
                  </div>

                  <div className="text-left">
                    <h2 className="heading">Create an account</h2>
                  </div>

                  <div className="pt-3">
                    <form id="reg-form" className="form-default" encType="multipart/form-data" onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="name" className="form-label">
                          Full Name
                        </label>
                        <input
                          type="text"
                          className="form-control rounded-0"
                          placeholder="Full Name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          type="text"
                          className="form-control rounded-0"
                          placeholder="Email"
                          name="email_or_phone"
                          value={formData.email_or_phone}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="form-group mb-0">
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <div className="position-relative">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control rounded-0"
                            placeholder="Password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                          />
                          <i
                            className={`password-toggle icon far ${showPassword ? "fa-eye-slash" : "fa-eye"
                              }`}
                            onClick={() => setShowPassword(!showPassword)}
                          ></i>
                        </div>
                        <div className="text-right mt-1">
                          <span className="fs-12 fw-400 text-gray-dark">
                            Password must contain at least 6 characters
                          </span>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="password_confirmation" className="form-label">
                          Confirm Password
                        </label>
                        <div className="position-relative">
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            className="form-control rounded-0"
                            placeholder="Confirm Password"
                            name="password_confirmation"
                            value={formData.password_confirmation}
                            onChange={handleChange}
                            required
                          />
                          <i
                            className={`password-toggle icon far ${showConfirmPassword ? "fa-eye-slash" : "fa-eye"
                              }`}
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          ></i>
                        </div>
                      </div>

                      <div className="mb-3">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            name="register_by"
                            checked={formData.register_by}
                            onChange={handleChange}
                            required
                          />
                          <span className="ms-2">
                            By signing up, you agree to our{" "}
                            <a href="/privacy-policy" className="fw-500">
                              terms and conditions
                            </a>
                          </span>
                        </label>
                      </div>

                      <div className="mb-4 mt-4">
                        <button
                          type="submit"
                          className="theme-btn btn-style-one rounded-0"
                        >
                          Create Account
                        </button>
                      </div>
                    </form>

                    <div className="text-center mb-3">
                      <span className="bg-white fs-12 text-gray">Or Join With</span>
                    </div>
                    <ul className="social text-center mb-4">
                      <li className="list-inline-item">
                        <a href="# " className="google">
                          <i className="fa-brands fa-google"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="# " className="facebook">
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>
                    </ul>

                    <p className="fs-12 text-gray mb-0">
                      Already have an account?
                      <a href="/login" className="ms-2 fw-700 animate-underline-primary">
                        Log In
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
