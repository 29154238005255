import React, { useEffect, useState } from 'react';

const SitemapPage = () => {
    return (
        <>
            <section className="about-page-title" style={{ backgroundImage: `url('/images/about-us.png')` }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="title">FAQ'S</h1>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SitemapPage;
