import React from 'react';

const VideoSection = () => {
    return (
        <section className="video-section">
            <div className="container-fluid">
                <div className="row align-items-center">
                    {/* Video */}
                    <div className="col-lg-7 col-sm-6">
                        <video className="w-100"
                            id="videobcg"
                            autoPlay
                            loop
                            muted
                            playsInline
                            preload="auto"
                            poster="/images/niyalivingvideo.mp4"
                        >
                            <source
                                type="video/mp4"
                                src="/images/niyalivingvideo.mp4"
                            />
                            Your browser does not support the video tag.
                        </video>
                    </div>

                    {/* Text */}
                    <div className="col-lg-5 col-sm-6">
                        <p className="text text-justify">
                            We have chosen solid wood and traditional assembly to design and create furniture full of personality. We have chosen solid wood and traditional assembly to design and create furniture full of personality.
                        </p>

                        {/* <p className="text-2">
                            FONT: Crimson Text
                        </p> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default VideoSection;
