import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import api from '../../../api';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { toast, ToastContainer } from 'react-toastify';
import OwlCarousel from 'react-owl-carousel';

const ProductSection = ({handleCart,handleWishlist}) => {
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loginData, setLoginData] = useState(null);

    useEffect(() => {
        const userData = sessionStorage.getItem('user_data');
        if (userData) {
            try {
                const parsedData = JSON.parse(userData);
                setLoginData(parsedData);
            } catch {
                setLoginData(null);
            }
        } else {
            setLoginData(null);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('/products/featured');
                setProducts(response.data.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const carouselOptions = {
        loop: true,
        margin: 10,
        nav: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 3
            }
        }
    };

    if (loading) {
        return (
            <section className="products-section">
                <div className="bg-pattern-7"></div>
                <div className="container-fluid">
                    <div className="text-center">
                        <h3 className="sec-title">OUR LATEST PRODUCTS</h3>
                    </div>
                    <div className="row">
                        {[...Array(6)].map((_, index) => (
                            <div className="product-block col-lg-4 col-sm-6" key={index}>
                                <div className="inner-box">
                                    <div className="image">
                                        <Skeleton height={200} />
                                    </div>
                                    <div className="content">
                                        <Skeleton height={20} width="80%" />
                                        <Skeleton height={20} width="50%" />
                                    </div>
                                    <div className="icon-box">
                                        <Skeleton height={30} width={30} circle={true} />
                                        <Skeleton height={30} width={30} circle={true} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }

    if (error) return <div>Error: {error}</div>;

    return (
        <section className="products-section">
            <div className="container-fluid">
                <div className="text-center">
                    <h3 className="sec-title">OUR LATEST PRODUCTS</h3>
                </div>

                {/* Mobile: Owl Carousel */}
                <div className="owl-carousel-wrapper d-block d-sm-none">
                    <OwlCarousel className="owl-theme" {...carouselOptions}>
                        {products.map((product) => (
                            <div className="product-block" key={product.id}>
                                <div className="inner-box">
                                    <div className="image">
                                        <Link to={`/products/${product.slug}`}>
                                            <img src={product.thumbnail_image} alt={product.name} />
                                        </Link>
                                    </div>
                                    <div className="content-box">
                                        <Link to={`/products/${product.slug}`} className="buy-btn">
                                            BUY NOW
                                        </Link>
                                        <Link
                                            to="#"
                                            onClick={() => handleCart(product.id, '', loginData?.id, 1)}
                                            className="cart-btn"
                                        >
                                            ADD TO CART
                                        </Link>
                                    </div>
                                    <div className="content">
                                        <h4><Link to={`/products/${product.slug}`}>{product.name}</Link></h4>
                                        <span className="price">{product.main_price}</span>
                                    </div>
                                    <div className="product-made">MADE TO ORDER</div>
                                    {/* <div className="product-new">NEW</div> */}
                                    <div className="icon-box">
                                        {loginData ? (
                                            <Link
                                                to="#"
                                                onClick={() => handleWishlist(product.slug)}
                                                className="ui-btn like-btn"
                                                aria-label="Add to Wishlist"
                                            >
                                                <i className="fa fa-heart"></i>
                                            </Link>
                                        ) : (
                                            <Link to="/login" className="ui-btn add-to-cart" aria-label="Login to Add to Cart">
                                                <i className="fa fa-heart"></i>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                </div>

                {/* Desktop: Grid layout */}


                <div className="row d-none d-sm-flex">
                    {products.map((product) => (
                        <div className="product-block col-lg-4 col-md-6 col-sm-6 col-6" key={product.id}>
                            <div className="inner-box">
                                <div className="image">
                                    <Link to={`/products/${product.slug}`}>
                                        <img src={product.thumbnail_image} alt={product.name} />
                                    </Link>
                                </div>
                                <div className="content-box">
                                    <Link to={`/products/${product.slug}`} className="buy-btn">
                                        BUY NOW
                                    </Link>
                                    <Link
                                        to="#"
                                        onClick={() => handleCart(product.id, '', loginData?.id, 1)}
                                        className="cart-btn"
                                    >
                                        ADD TO CART
                                    </Link>
                                </div>
                                <div className="content">
                                    <h4>
                                        <Link to={`/products/${product.slug}`}>{product.name}</Link>
                                    </h4>
                                    <span className="price">{product.main_price}</span>
                                </div>
                                <div className="product-made">MADE TO ORDER</div>
                                <div className="icon-box">
                                    {loginData ? (
                                        <Link
                                            to="#"
                                            onClick={() => handleWishlist(product.slug)}
                                            className="ui-btn like-btn"
                                            aria-label="Add to Wishlist"
                                        >
                                            <i className="fa fa-heart"></i>
                                        </Link>
                                    ) : (
                                        <Link to="/login" className="ui-btn add-to-cart" aria-label="Login to Add to Cart">
                                            <i className="fa fa-heart"></i>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </section>
    );
};

export default ProductSection;
