import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from "react-router-dom";
import api from '../../../api';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const BannerSection = () => {
  const [slides, setSlides] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const options = {
    loop: false,
    margin: 10,
    nav: false,
    autoplay: false,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    items: 1,
    dots: true,
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/sliders');
        setSlides(response.data.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return (
    <section className="banner-section-one">
      <div className="slide-item">
        <Skeleton height={400} width="100%" />
        <div className="container-fluid">
          <div className="content-box text-center">
            <Skeleton height={40} width="60%" />
            <div className="btn-box">
              <Skeleton height={40} width={120} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );


  if (error) return <div>Error: {error.message}</div>;

  return (
    <section className="banner-section-one">
      <OwlCarousel className="banner-carousel default-dots owl-theme" {...options}>
        {slides.map((slide, index) => (
          <div className="slide-item" key={slide.id || index}>
            <div
              className="bg-image"
              style={{ backgroundImage: `url(${slide.photo})` }}
            ></div>
            <div className="container-fluid">
              <div className="content-box text-center">
                <h2 className="title animate-3 fw-300">
                {slide.heading}
                </h2>
                <div className="btn-box animate-5">
                  <Link to={slide.url} className="theme-btn btn-style-three">
                    <span className="btn-title">{slide.link_text}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </section>
  );
};

export default BannerSection;
