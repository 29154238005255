import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const PaymentResponse = () => {
    const [paymentStatus, setPaymentStatus] = useState('');
    const [message, setMessage] = useState('');
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const response = queryParams.get('response');

        if (response === '1') {
            setPaymentStatus('success');
            setMessage('Payment was successful!');
        } else {
            setPaymentStatus('failed');
            setMessage('There was an issue with your payment.');
        }
    }, [location]);

    return (
        <div>
            {paymentStatus === 'success' ? (
                <section className="error-page pb-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="error-page__inner">
                                    <div className="error-page__title-box">
                                        <img style={{ height: '300px' }}
                                            src="/images/success.png"
                                            alt="Payment success"
                                        />
                                        <h3 className="success-page__sub-title pt-2">
                                            Payment Successful!
                                        </h3>
                                        <p>Thank you for your purchase. Your order has been confirmed!</p>
                                    </div>
                                    <a
                                        className="theme-btn btn-style-one shop-more me-3"
                                        href="/products"
                                    >
                                        <span className="btn-title">Shop More</span>
                                    </a>
                                    <a
                                        className="theme-btn btn-style-one shop-more"
                                        href="/purchase-history"
                                    >
                                        <span className="btn-title">Order Details</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <section className="error-page pb-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="error-page__inner">
                                    <div className="error-page__title-box">
                                        <img style={{ height: '300px' }}
                                            src="/images/error.png"
                                            alt="Payment failed"
                                        />
                                        <h3 className="error-page__sub-title pt-2">
                                            Payment Failed
                                        </h3>
                                        <p>
                                            We encountered an issue processing your payment. Please try
                                            again or contact support.
                                        </p>
                                    </div>
                                    <a
                                        className="theme-btn btn-style-one retry-payment"
                                        href="/checkout"
                                    >
                                        <span className="btn-title">Retry Payment</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </div>
    );
};

export default PaymentResponse;
