import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import api from '../../../api';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import OwlCarousel from 'react-owl-carousel';

const ShopCategory = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('/categories');
                setCategories(response.data.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // Owl Carousel Options
    const carouselOptions = {
        loop: true,
        margin: 10,
        nav: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 2
            },
            600: {
                items: 2
            },
            1000: {
                items: 4
            }
        }
    };

    if (loading) {
        return (
            <section className="projects-section-six">
                <div className="container-fluid">
                    <div className="text-center">
                        <h3 className="sec-title">SHOP BY CATEGORY</h3>
                    </div>
                    <div className="row">
                        {[...Array(6)].map((_, index) => (
                            <div className="col-lg-4 col-md-6 mb-4" key={index}>
                                <div className="project-block-six">
                                    <div className="inner-box">
                                        <figure className="image">
                                            <Skeleton height={200} />
                                        </figure>
                                        <div className="content-box">
                                            <div className="inner">
                                                <span className="cat"><Skeleton width={100} /></span>
                                                <h4 className="title fw-300 text-uppercase">
                                                    <Skeleton width={150} />
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }

    if (error) return <div>Error: {error.message}</div>;

    return (
        <section className="projects-section-six">
            <div className="container-fluid">
                <div className="text-center">
                    <h3 className="sec-title">SHOP BY CATEGORY</h3>
                </div>

                <div className="owl-carousel-wrapper">
                    <OwlCarousel className="projects-carousel-six owl-theme" {...carouselOptions}>
                        {categories.map((category) => (
                            <div className="project-block-six" key={category.id}>
                                <div className="inner-box">
                                    <figure className="image">
                                        <Link to={`/category/${category.slug}`}>
                                            <img src={category.cover_image} alt={category.name} />
                                        </Link>
                                    </figure>
                                    <div className="content-box">
                                        <Link className="theme-btn btn-style-three" to={`/category/${category.slug}`}>{category.name}</Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                </div>
            </div>
        </section>
    );
};

export default ShopCategory;
