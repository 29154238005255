import React, { useState, useEffect } from "react";
import api from '../api';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";

const NewsletterPopup = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Check if the popup has already been shown
        const isPopupShown = localStorage.getItem("newsletterPopupShown");
        if (!isPopupShown) {
            setShowPopup(true);
        }
    }, []);

    const handleClose = () => {
        setShowPopup(false);
        // Save the state in localStorage
        localStorage.setItem("newsletterPopupShown", "true");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await api.post('/subscriber-add', { email });
            if (response.data && response.data.result) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message || "An error occurred while processing your request.");
            }
        } catch (err) {
            toast.error(err.response?.data?.message || "An error occurred. Please try again.");
        }
    };

    return (
        showPopup && (
            <div
                className="modal fade show"
                id="newsletterPopup"
                tabIndex="-1"
                aria-modal="true"
                role="dialog"
                style={{ display: "block", paddingRight: "4px" }}
            >
                <div className="modal-dialog newsletter-popup modal-dialog-centered">
                    <div className="modal-content">
                        <Link to="#"
                            className="btn-close"
                            onClick={handleClose}
                        ><span className="icon lnr-icon-cross"></span></Link>
                        <div className="row p-0 m-0">
                            <div className="col-md-6 p-0 d-flex align-items-center">
                                <div className="block-newsletter w-100">
                                    <h3 className="block__title">Sign Up to Our Newsletter</h3>
                                    <p>
                                        Be the first to get the latest news about trends,
                                        promotions, and much more!
                                    </p>
                                    <div className="newsletter">
                                        <form className='d-flex justify-content-center align-items-center' onSubmit={handleSubmit}>
                                            <input
                                                type="email"
                                                name="EMAIL"
                                                placeholder="Email Address"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                            <button
                                                type="submit"
                                                className="theme-btn btn-style-one"
                                            >
                                                Subscribe
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 p-0 d-none d-md-block">
                                <div className="newsletter-popup__bg h-100 w-100">
                                    <img
                                        loading="lazy"
                                        src="../images/collection.png"
                                        className="h-100 w-100 object-fit-cover d-block"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default NewsletterPopup;
